<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createUser">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t("CrearUsuario") }}
            </h4>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <ImageDropzone
              ref="images"
              :files="files"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('Nombre')"
              label-for="account-name"
            >
              <b-form-input
                v-model="name"
                name="name"
                :placeholder="$t('Nombre')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Apellidos')"
              label-for="account-surname"
            >
              <b-form-input
                v-model="surname"
                :placeholder="$t('Apellidos')"
                name="surname"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-checkbox
                class="mb-1 mt-1"
                id="emailTrue"
                :checked="false"
                name="check-button"
                v-model="not_email"
                inline
            >
              <span>{{$t('NoEmail')}}</span>
            </b-form-checkbox>
          </b-col>

          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              :rules="requiredEmail"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="email"
                  name="email"
                  :disabled="disableEmail"
                  :placeholder="$t('Email')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Username')"
                :rules="requiredUsername"
            >
              <b-form-group
                  :label="$t('Username')"
                  label-for="account-username"
              >
                <b-form-input
                    v-model="username"
                    name="username"
                    :disabled="disableUsername"
                    :placeholder="$t('Username')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Telefono')"
                rules="max:9|min:9"
            >
            <b-form-group
              :label="$t('Telefono')"
              label-for="account-phone"
            >
              <b-input-group>
                <template #prepend>
                  <vue-country-code
                    @onSelect="onSelect"
                    :preferredCountries="['es']">
                  </vue-country-code>
                </template>

                <b-form-input
                  type="number"
                  v-model="phone"
                  name="company"
                  :placeholder="$t('Telefono')"
                />

              </b-input-group>
            </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Dni')"
            >
              <b-form-group
                  :label="$t('Dni')"
                  label-for="account-dni"
              >
                <b-form-input
                    v-model="dni"
                    name="dni"
                    :placeholder="$t('Dni')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Rol')"
              rules="required"
            >
              <b-form-group
                :label="$t('Rol')"
                label-for="account-rols"
              >
                <v-select
                  v-model="roles"
                  :get-option-label="option => $t(option.display_name)"
                  :filterable="false"
                  :searchable="false"
                  :options="selectRoles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Contraseña')"
              rules="required|min:8"
            >
              <b-form-group
                :label="$t('Contraseña')"
                label-for="account-password"
              >
                <b-input-group>
                  <template #append>
                    <b-input-group-text>
                      <div
                        class="append-text"
                        @click="show1 = !show1"
                      >
                        <span>
                          <feather-icon
                            :icon="show1 ? 'EyeIcon' : 'EyeOffIcon'"
                            :svg-classes="['text-white', 'h-5 w-5 mt-2']"
                          />
                        </span>
                      </div>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="password"
                    :type="show1 ? 'text' : 'password'"
                    name="password"
                    placeholder="Contraseña"
                    hint="At least 8 characters"
                    counter
                    outlined
                    class="w-full"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="currentRole !== 'admin_cliente'"
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Compañía')"
              rules="required"
            >
              <b-form-group
                :label="$t('Compañía')"
                label-for="account-company"
              >
                <v-select
                  v-model="companies"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectCompanies"
                  :placeholder="$t('Compañía')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="currentRole !== 'admin_cliente'"
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Cliente')"
              :rules="
                roles &&
                  (roles.name === 'admin_cliente' ||
                    roles.name === 'operario_cliente' ||
                    roles.name === 'prl_cliente')
                  ? 'required'
                  : ''
              "
            >
              <b-form-group
                :label="$t('Cliente')"
                label-for="account-clients"
              >
                <v-select
                  v-model="clients"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectClients"
                  multiple
                  :disabled="disabledClients"
                  :placeholder="$t('Cliente')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" v-if="roles && roles.name === 'prl_cliente'">
            <b-form-group
                :label="$t('Localizaciones')"
                label-for="locations"
            >
              <LocationsSelect
                  v-model="selectLocations"
                  :multiple="true"
                  :clients="clients"
              />
            </b-form-group>
          </b-col>
          <!--<b-col sm="12">
            <b-form-group
              label="Departamentos"
              label-for="account-departments"
            >
              <div
                v-for="item in departmentsSelect"
                class="mt-2"
              >
                <p class="mb-1">
                  <strong>{{ item.name }}</strong>
                </p>
                <b-row>
                  <b-col
                    v-for="dep in item.data"
                    cols="12"
                    lg="3"
                  >
                    <b-form-checkbox
                      v-model="departments"
                      :value="dep.id"
                      plain
                    >
                      {{ dep.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
          </b-col>-->
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BFormCheckbox,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import VueCountryCode from 'vue-country-code-select'
import LocationsSelect from '@/components/locations/select/LocationSelect.vue'

export default {
  components: {
    LocationsSelect,
    BButton,
    BInputGroupText,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    VueCountryCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      companies: '',
      clients: [],
      roles: '',
      //departments: [],
      selectLocations: [],
      files: [],
      profileFile: null,
      required,
      email,
      not_email: false,
      disableUsername: true,
      disableEmail: false,
      requiredEmail: 'required|email|emailUnique',
      requiredUsername: '',
      userPrepare: false,
      disabledClients: true,
      surname: '',
      username: '',
      name: '',
      phone: '',
      dni: '',
      codePhone: 'es',
      codePhoneSave: JSON.stringify({
        iso: 'ES',
        code: 34,
      }),
      avatar: '',
      show1: false,
      password: '',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      selectCompanies: 'companies/getListCompanies',
      userSelectRoles: 'users/getRoles',
      selectClients: 'clients/getSelectClients',
      //departmentsSelect: 'departments/getSelectDepartments',
      currentRole: 'auth/getRole',
      currentCompany: 'companies/getCurrentCompany',
    }),
    selectRoles() {
      const arrayOutput = []
      this.userSelectRoles.forEach(element => {
        if (element.name !== 'admin_cliente') {
          arrayOutput.push(element)
        } else if (!this.not_email) {
          arrayOutput.push(element)
        }
      })
      return arrayOutput
    },
  },
  watch: {
    companies() {
      this.clients = []

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        })
        this.disabledClients = false
      } else {
        this.disabledClients = true
      }
    },
    clients() {
      /*this.departments = []

      const aux = this
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients })
      } else {
        this.setSelectDepartments([])
      }*/
    },
    not_email() {
      if (this.not_email) {
        this.disableUsername = false
        this.disableEmail = true
        this.requiredEmail = ''
        this.requiredUsername = 'required'
      } else {
        this.disableUsername = true
        this.disableEmail = false
        this.requiredEmail = 'required|email|emailUnique'
        this.requiredUsername = ''
      }
      this.roles = ''
    },
  },
  methods: {
    ...mapActions({
      create: 'users/create',
      searchCompanies: 'companies/searchCompanies',
      searchClients: 'clients/selectClients',
      //selectDepartments: 'departments/selectDepartments',
      getRoles: 'users/getRoles',
    }),
    ...mapMutations({
      //setSelectDepartments: 'departments/setSelectDepartments',
      setSelectLocations: 'locations/setSelectLocations',
    }),
    setClients() {
      if (this.currentRole == 'admin_cliente') {
        this.clients = [this.currentClient]
        //this.selectDepartments({ clients: this.clients })
      }
    },
    handleSubmit() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ user: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()

      if (this.roles) {
        data.append('role', this.roles.name)
      }
      /*if (this.departments.length > 0) {
        this.departments.forEach(element => {
          if (element && element !== null) {
            data.append('departments[]', element)
          }
        })
      } else {
        data.append('departments', '')
      }*/

      if (this.selectLocations.length > 0 && this.roles.name === 'prl_cliente') {
        this.selectLocations.forEach(element => {
          if (element && element !== null) {
            data.append('locations[]', element.id)
          }
        })
      } else {
        data.append('locations', '')
      }

      data.append('name', this.name)
      data.append('not_email', this.not_email)
      if (this.not_email) {
        data.append('email', '')
        data.append('username', this.username)
      } else {
        data.append('email', this.email)
        data.append('username', this.email)
      }
      data.append('phone', this.phone)
      data.append('code_phone', this.codePhoneSave)
      data.append('surname', this.surname)
      data.append('password', this.password)
      data.append('dni', this.dni)

      if (this.currentRole == 'admin_cliente') {
        data.append('companies[]', this.currentCompany.id)
        data.append('clients[]', this.currentClient.id)
      } else {
        if (this.companies) {
          data.append('companies[]', this.companies.id)
        }
        if (this.clients.length > 0) {
          this.clients.forEach(element => {
            data.append('clients[]', element.id)
          })
        } else {
          data.append('clients', '')
        }
      }

      return data
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = dialCode
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      })
    },
  },
  async created() {
    await this.searchCompanies()
    await this.setClients()
    await this.getRoles()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
